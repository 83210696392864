<script setup lang="ts">
import { isEmpty } from 'lodash-es';
import { useAirlinePartner, useNavigation, useTracker, useRouteBaseName, useRoute } from '#imports';

interface IProps {
  limit: number;
}

const props = defineProps<IProps>();
const { jitsuEvent } = useTracker();
const { airlinePartners, getAirlineLogoUrl, getAirlinePath } = await useAirlinePartner(props.limit);
const getRouteBaseName = useRouteBaseName();
const route = useRoute();
</script>

<template>
  <div
    v-if="!isEmpty(airlinePartners)"
    class="flex flex-col items-center"
    data-testid="airline-partners-container"
  >
    <h2 class="font-bold text-gray-darkest text-extra mb-40 text-center">
      {{ $t('global.airlinepartnersheader') }}
    </h2>

    <div class="grid grid-cols-9 gap-50 grid-rows-2 mb-40">
      <NuxtLink
        v-for="(airline, index) in airlinePartners"
        :key="index"
        data-testid="airlinePartners-logo-button"
        :to="getAirlinePath(airline)"
        external
        no-rel
        @click="
          jitsuEvent('user-click-button', {
            event_name: 'airlinepartners-defaultlist',
            object_name: 'airline-partners',
            object_parameter: 'airline',
            airline: airline.code,
            screen_name: '',
          })
        "
      >
        <NuxtImg
          :alt="airline.name + ' Logo Images'"
          :src="getAirlineLogoUrl(airline.code)"
          format="webp"
          loading="lazy"
          height="50"
          width="50"
          fit="scale-down"
          sizes="lg:50vw"
        />
      </NuxtLink>
    </div>

    <ApzButton
      tag="nuxt-link"
      type="primary"
      variant="outline"
      class="py-15 px-25"
      data-testid="airlinePartners-seeAllAirline-button"
      :to="useNavigation().navigationPath('/airlines')"
      external
      no-rel
      @click="
        jitsuEvent('user-click-button', {
          event_name: 'airlinepartners-seemore',
          object_name: 'airline-partners',
          object_parameter: 'see-all',
          screen_name: getRouteBaseName(route) === 'index' ? 'home' : (getRouteBaseName(route) as string),
        })
      "
    >
      {{ $t('global.seeairlinepartners') }}
    </ApzButton>
  </div>
</template>
